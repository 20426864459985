<template>
  <div>
    <home-head/>
    <home-sub-head/>
    <home-benefits/>
    <home-why-it-works/>
    <home-signup/>
    <home-founders/>
    <contact-us/>
  </div>
</template>

<script>
export default {
  name: 'HomeView',

  components: {
    HomeHead: () => import('@/components/HomeHead.vue'),
    HomeSubHead: () => import('@/components/HomeSubHead.vue'),
    HomeBenefits: () => import('@/components/HomeBenefits.vue'),
    HomeWhyItWorks: () => import('@/components/HomeWhyItWorks.vue'),
    HomeSignup: () => import('@/components/HomeSignup.vue'),
    HomeFounders: () => import('@/components/HomeFounders.vue'),
    ContactUs: () => import('@/components/ContactUs.vue')
  }
}
</script>
