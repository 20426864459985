<template>
  <v-app>
    <app-bar/>
    <router-view/>
    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
      >
        <v-img src="@/assets/arrow-up.png" style="max-width: 24px;"></v-img>
      </v-btn>
    </v-fab-transition>
    <app-footer/>
    <v-bottom-sheet v-model="cookieBar" persistent>
      <v-sheet class="text-center" height="150px" color="grey darken-4">
        <v-btn
          class="mt-6 st-btn"
          depressed
          color="primary"
          style="min-width: 100px;"
          @click="setCookie"
        >Accept</v-btn>
        <div class="white--text" style="padding: 20px;">
          Our site uses cookies. By continuing to use our site, you are agreeing to our
          <router-link
            to="/cookie-policy"
            class="text-decoration-none"
          >
            Cookie Policy
          </router-link>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-app>
</template>

<style scoped>
#app {
  font-family: Sen, Avenir, Roboto, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.st-btn {
  color: #191919 !important;
  font-weight: 600 !important;
}

.st-btn::before {
  background: #FFFFFF !important;
}

.st-btn:hover {
  background: #FFFFFF !important;
  border: solid #EAC30D 0.1em;
}
</style>

<script>
import Cookie from 'js-cookie'

export default {
  name: 'App',

  components: {
    AppBar: () => import('@/components/AppBar.vue'),
    AppFooter: () => import('@/components/AppFooter.vue')
  },

  computed: {
    appCookie () {
      return this.appCookieSet
    }
  },

  data: () => ({
    fab: false,
    cookieBar: '',
    appCookieSet: false
  }),

  beforeMount () {
    this.appCookieSet = this.getCookie()
  },

  mounted () {
    if (!window.gtag) {
      const s = () => {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script')
          script.async = true
          script.defer = true
          script.src = 'https://www.googletagmanager.com/gtag/js?id=' +
            process.env.VUE_APP_GOOGLE_GA_MEASUREMENT_ID
          document.head.appendChild(script)

          script.onload = () => {
            try {
              resolve()
            } catch (err) {
              reject(err)
            }
          }
        })
      }
      s().then(function () {
        window.dataLayer = window.dataLayer || []
        function gtag () {
          window.dataLayer.push(arguments)
        }
        gtag('js', new Date())
        gtag('config', process.env.VUE_APP_GOOGLE_GA_MEASUREMENT_ID)
        window.gtag = gtag
      })
    }
  },

  updated () {
    if (this.$route.name === 'cookiePolicy') {
      this.cookieBar = false
    } else {
      if (this.getCookie() === undefined) {
        this.cookieBar = true
      } else {
        this.cookieBar = false
      }
    }
  },

  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') {
        return
      }
      const top = window.pageYOffset ||
        e.target.scrollTop || 0
      this.fab = top > 80
    },

    toTop () {
      this.$vuetify.goTo(0)
    },

    getCookie: function () {
      return Cookie.get('startupy')
    },

    setCookie: function () {
      Cookie.set('startupy', true, {
        expires: 30,
        /* domain: process.env.VUE_APP_DOMAIN, */
        secure: true,
        sameSite: 'strict'
      })
      this.cookieBar = false
      this.appCookieSet = true
    }
  }
}
</script>
